/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import 'all/custom/variables.scss';

// Import Bootstrap source files (Required file)
@import 'all/bootstrap/bootstrap.scss';
@import 'all/custom/custom.scss';

//This is for the icons (Required file)
@import 'all/icons/font-awesome/css/fontawesome-all.css';
@import 'all/icons/simple-line-icons/css/simple-line-icons.css';
@import 'all/icons/weather-icons/css/weather-icons.css';
@import 'all/icons/themify-icons/themify-icons.css';
@import 'all/icons/flag-icon-css/flag-icon.min.css';
@import 'all/icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'all/icons/crypto-icons/cryptocoins.css';


iframe {
  height: calc(100vh - 70px);
  width: 100%;
  border: 0;
}

.page-wrapper > .page-content {
  padding: 0;
}

.logo-text .light-logo {
  width: 120px;
}
.logo-text .dark-logo {
  width: 120px;
}

.logo-icon .light-logo {
  width: 40px;
}
.logo-icon .dark-logo {
  width: 40px;
}
